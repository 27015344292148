<script>
import AgGridTableNumber from "@/components/Forms/FormAddClient/AgGridTableNumber";
import Actions from "../Actions";
import formSaveMixin from "@/crm_components/form_save/FormSaveMixin";
export default {
  name: "formCreateUser",
  components:{
    AgGridTableNumber,
    Actions
  },
  mixins:[
    formSaveMixin
  ],
  data(){
    return {
      form_create_user:[
        {
          model: 'first_name',
          show_label: true,
          validate: 'required',
        },
        {
          model: 'last_name',
          show_label: true,
          validate: 'required',
        },
        {
          model: 'middle_name',
          show_label: true,
        },
        {
          model: 'roles',
          type:'select',
          end_point:'organizations/roles/',
          validate: 'required',
          mode:'multiple',
        },
        {
          model: 'number',
          show_label: true,
          validate: 'required',
          placeholder: '+7',
          mask:'+7##########'
        },
        {
          model:'add_organization_request',
          type:'button-add',
          show_label: false,
          sub_items:[],
          add_items:[
            {
              model: 'organization_request',
              type:'select',
              show_label: true,
              label: this.$t('organization'),
              end_point:'organizations/organizations/',
              placeholder: this.$t('organization'),
              validate: 'required',
            },
            {
              model: 'number',
              label: this.$t('number'),
              placeholder: this.$t('number'),
              show_label: true,
            }
          ]
        }

      ],
      column_defs_create_org:[
        {
          headerName: "",
          field: "name",
          cellRendererFramework: 'Actions',
          minWidth: 75,
          maxWidth: 75,
          cellRendererParams: {
            onEditClicked: params => {
              this.open_on_edit_user({data:params.data}, true)
            },
            onDeleteClicked: params => {
              this.delete_method_user('create_user_api_event_bus', 'organizations/create_user/', params.data)
            },

          }
        },
        {
          headerName: "№",
          field: "full_name",
          minWidth: 75,
          maxWidth: 75,
          cellRendererFramework: 'AgGridTableNumber',
        },
        {
          headerName: this.$t('full_name'),
          field: 'full_name',
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: this.$t('roles'),
          field: 'str_names_roles',
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: this.$t('organization_request'),
          field: 'str_names_organization_request',
          sortable: true,
          filter: true,
          width: 200,
        },
      ]
    }
  },
  methods:{
    open_on_edit_user(data, edit = false){
      this.$refs.refUsersShowDrawer.data = data
      if (edit){
        this.$refs.refUsersShowDrawer.edit()
      }
    },
    delete_method_user(name_event_bus, end_point, data){
      this.$confirm({
        title: this.$t('are_you_sure_delete'),
        okText: this.$t('yes'),
        okType: 'danger',
        cancelText: this.$t('no'),
        onOk: () => {
          this.handleSubmit(null, name_event_bus+"_delete", end_point, 'delete', data)
          this.$emit('close')
        },
        onCancel() {
          console.log('Cancel');
        },
      });

    }
  }
}
</script>

<style scoped>

</style>
