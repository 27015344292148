<template>
  <div>
    <div class="flex justify-between py-2">
      <a-button
          type="primary"
          class="default-button mr-1 base_text flex justify-center items-center"
          size="small"
          @click="add_user">
        <a-icon type="plus" style="font-size: large; margin-right: 0px; width: 20px; height: 20px" />
        <div class="ml-2">
          {{ $t('add') }}
        </div>
      </a-button>
    </div>
    <card>
      <AgGridTable
          ref="refAgGridTableCreateRequest"
          updated_event_bus="updated_create_user"
          :column_defs="column_defs_create_org"
          :end_point="'organizations/create_user/'"
          name_event_bus="create_user_api_event_bus"/>
    </card>
    <draw-create-user ref="refDrawCreateUser" :title="$t('add')" @after_create="after_update"/>
    <users-show-drawer ref="refUsersShowDrawer" @after_update="after_update"/>
  </div>
</template>

<script>
import DrawCreateUser from "./DrawCreateUser";
import AgGridTable from "@/components/Forms/FormAddClient/AgGridTable";
import formCreateUser from "./Mixins/formCreateUser";
import Card from "@/crm_components/card/Card";
import UsersShowDrawer from "./Users/UsersShowDrawer";
export default {
  name: "CreateUsersMain",
  components: {
    UsersShowDrawer,
    DrawCreateUser,
    AgGridTable,
    Card,
  },
  mixins:[
    formCreateUser,
  ],
  methods:{
    add_user(){
      this.$refs.refDrawCreateUser.visible = true
    },
    after_update(data){
      this.$refs.refAgGridTableCreateRequest.updateMessage(data.data)
    }
  }
}
</script>

<style scoped>

</style>
