<template>
  <div>
    <users-edit :visible="visible_edit" :data="data" ref="refUsersEdit" @close="close" @after_update="after_update"/>
  </div>
</template>

<script>
import UsersEdit from "./UsersEdit";
export default {
  name: "UsersShowDrawer",
  data(){
    return {
      data: null,
      visible_edit: false,
      visible: false,
    }
  },
  methods:{
    async close(){
      this.visible = await false;
      this.visible_edit = await false;
    },
    after_update(val){
      this.$emit('after_update', val)
    },

    async edit(){
      this.visible = await false;
      this.visible_edit = await true;

      await this.$nextTick(); // Подождем, пока DOM обновится

      // Теперь ссылки на компоненты должны быть доступны

      if (this.$refs.refUsersEdit.$refs.refFormEdit) {
        let uid_roles = []
        this.data.data.roles_did.forEach((item) => {
          uid_roles.push(item.uid)
        })
        // console.log('uid_roles', uid_roles)
        // console.log('this.data.data.roles', this.data.data.roles)
        this.$refs.refUsersEdit.$refs.refFormEdit.clientData = this.data.data
        this.$refs.refUsersEdit.$refs.refFormEdit.clientData['roles'] = uid_roles

        let clone_form = JSON.parse(JSON.stringify(this.$refs.refUsersEdit.form_create_user))
        let index_add_phone = this.$refs.refUsersEdit.form_create_user.findIndex((item) => item.model === `add_organization_request`)
        let add_items = this.$refs.refUsersEdit.form_create_user[index_add_phone].add_items
        let all_clone_add_item = []
        this.data.data.phone_number.forEach((item, index) => {
          if (item.main){
            this.$refs.refUsersEdit.$refs.refFormEdit.clientData.number = item.number
          }else{
            let clone_add_item = []
            add_items.forEach((item) => {
              clone_add_item.push({
                ...item,
                model: `${item.model}_${index}`,
              })
            })
            all_clone_add_item.push(clone_add_item)
            this.$refs.refUsersEdit.$refs.refFormEdit.clientData[`organization_request_${index}`] = item.organization_request
            this.$refs.refUsersEdit.$refs.refFormEdit.clientData[`number_${index}`] = item.number

            let clone_control_model_name = this.$refs.refUsersEdit.$refs.refFormEdit.control_model_name
            if ('add_organization_request' in clone_control_model_name) {
              this.$refs.refUsersEdit.$refs.refFormEdit.control_model_name['add_organization_request'] += 1
            } else {
              this.$refs.refUsersEdit.$refs.refFormEdit.control_model_name['add_organization_request'] = 1
            }

          }

        })
        console.log('clone_form', clone_form)
        console.log('clone_add_item', all_clone_add_item)
        clone_form[index_add_phone].sub_items = all_clone_add_item
        console.log('clone_form', clone_form)
        this.$refs.refUsersEdit.$refs.refFormEdit.form_items_clone = clone_form
      } else {
        console.error('Reference to refFormEdit is still undefined.');
      }
    },
  },
  components:{
    UsersEdit
  },
}
</script>

<style scoped>

</style>
