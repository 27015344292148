<template>
  <div>
    <custom-drawer :visible="visible" ref="refCustomDrawerEdit" v-if="data" :key_delete_router="['phone_number', 'stab']" @close="close">
      <template slot="title">
        {{ $t('edit') }}
      </template>
      <template slot="content">
        <div class="w-full">
          <div style="margin-top: 50px;" class="w-1/2">
            <FormSelect :formItems="form_create_user" ref="refFormEdit" style="margin-top: 50px;" :cols="1"/>
          </div>
        </div>
      </template>
      <template slot="content_bottom">
        <a-button
            type="primary"
            class="default-button mr-1"
            size="small"
            @click="updated_organization">
          Сохранить
        </a-button>
      </template>

    </custom-drawer>
  </div>
</template>

<script>
import formSaveMixin from "@/crm_components/form_save/FormSaveMixin";
import FormSelect from "@/components/Forms/FormAddClient/FormSelect";
import CustomDrawer from "@/crm_components/CustomDrawer/CustomDrawer";
import formCreateUser from "../Mixins/formCreateUser";

export default {
  name: "UsersEdit",
  components:{
    FormSelect,
    CustomDrawer,
  },
  mixins:[
    formCreateUser,
    formSaveMixin
  ],
  props:{
    visible:{
      type:Boolean,
      default: false
    },
    data:{
      type: Object,
      default: {}
    }
  },
  computed:{
    form_items_edit(){
      let clone = JSON.parse(JSON.stringify(this.formItemsEdit))
      this.data.data.phone_numbers.forEach((item, index) => {
        clone.push({
          model: `number_${index}`,
          show_label: true,
          validate: 'required',
          placeholder: '+7',
          mask:'+7##########'
        })
      })
      return clone
    }
  },
  methods:{
    updated_organization(val){
      const organizationRequests = Object.keys(this.$refs.refFormEdit.clientData)
          .filter(key => key.startsWith('organization_request_'))
          .map(key => this.$refs.refFormEdit.clientData[key]);
      this.$refs.refFormEdit.clientData['organization_request'] = organizationRequests;
      // this.$refs.refFormEdit.clientData['regions'] = [this.$refs.refFormEdit.clientData['region_fk']]
      this.handleSubmit(this.$refs.refFormEdit, 'updated_create_user', 'organizations/create_user/', 'update')
    },
    afterUpdate(val){
      this.$emit('after_update', val)
    },
    close(){
      this.$emit('close')
    },

  }
}
</script>

<style scoped>

</style>
