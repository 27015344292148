import { render, staticRenderFns } from "./CreateUsersMain.vue?vue&type=template&id=5924fc21&scoped=true"
import script from "./CreateUsersMain.vue?vue&type=script&lang=js"
export * from "./CreateUsersMain.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5924fc21",
  null
  
)

export default component.exports